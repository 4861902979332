import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import { Container, Section } from "../components/SectionTags"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import {Nodata, NodataTitle, NodataDesc, NodataAction, NodataContainer} from "../components/Nodata"
import PrimaryButton from "../components/Button/PrimaryButton"


const ThanksPage = () => (  
  <Layout>
    <Seo title="Thank you for connecting with us" description="Our Representative will get back to you with the requested building quote
      soon" />
    <div className="sticky-wrapper sticky">
      <Header />
    </div>
    <Section xpt="200px" xpb="80px" mpt="200px" mpb="80px" pt="140px" pb="60px">
      <Container>
        <Nodata>
          <NodataContainer>
            <NodataTitle className='mb-0'>THANK YOU</NodataTitle>
            <NodataDesc><p>For requesting a quote. Our team will get in touch with you soon.</p>
            </NodataDesc>
            <NodataAction><Link to="/"><PrimaryButton text="Back To Home" /></Link>
            </NodataAction>
          </NodataContainer>
        </Nodata>
      </Container>
    </Section>
    <EmergencyEstimateCTA />
  </Layout>
)

export default ThanksPage