import styled from 'styled-components'

export const Nodata = styled.div`
  position: relative;  
  text-align:center;
`
export const NodataContainer= styled.div`
  position: relative;    
  width:100%;
  margin:0 auto;
  max-width:${props=>props.maxWidth};
`
NodataContainer.defaultProps = {
	maxWidth: "980px",
}

export const NodataMedia = styled.div`
  position: relative;
  margin-bottom:30px;
  .gatsby-image-wrapper{
    display:inline-block;
  }
`
export const NodataTitle = styled.h1`
  font-family: 'Red Hat Display', sans-serif;
  color:#02044A;
  margin-bottom:10px;  
  @media (min-width:992px) {
    margin-bottom:15px;  
  }
`
export const NodataSubTitle = styled.h2`
  font-family: 'Red Hat Display', sans-serif;
  color:#02044A;
  margin-bottom:10px;  
  @media (min-width:992px) {
    margin-bottom:15px;  
  }
`
export const NodataDesc = styled.div`
  text-align:${props=>props.textAlign};  
  margin-bottom:20px;  
  @media (min-width:992px) {
    margin-bottom:30px;  
  }
  @media (min-width:1200px) {
    margin-bottom:40px;  
  }
  @media (min-width:1600px) {
    margin-bottom:60px;  
  }
  p{
    color: ${props=>props.color};
  }
`
NodataDesc.defaultProps = {
  color: "#373636",
}
export const NodataAction = styled.div`
 
`